import React from "react"
import Layout from "../components/Layout"
import NewsletterForm from "../components/NewsletterForm"
import Section from "../components/Section"

export interface InformiertBleibenProps {}

const InformiertBleiben: React.SFC<InformiertBleibenProps> = () => {
  return (
    <Layout>
      <Section>
        <NewsletterForm />
      </Section>
    </Layout>
  )
}

export default InformiertBleiben
